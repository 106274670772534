import StakingPoolCollection from '@/domains/collections/StakingPoolCollection'
import { StakingPool as SdkStakingPool, TokenAmount } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'
import MerkleDistributor from '@/domains/entities/MerkleDistributor'
import PendleMarket from '@/domains/entities/PendleMarket'

const contractPromiseHandler = (fn) =>
  new Promise((resolve, reject) => {
    fn()
      .then((response) => response.wait(1).then((res) => setTimeout(() => resolve(res), 2000)))
      .catch((error) => reject(error))
  })
export default class StakingPool {
  static className = 'StakingPool'

  get className() {
    return 'StakingPool'
  }

  constructor({
    address,
    inputToken,
    network,
    abiInfo,
    contractType,
    rewardTokens = [],
    interestTokens = [],
    disabled = false,
    stakableProtocol = [],
  }) {
    this.address = address
    this.contractType = contractType
    this.inputToken = inputToken
    this.network = network
    this.abiInfo = abiInfo
    this.rewardTokens = rewardTokens
    this.interestTokens = interestTokens
    this.disabled = disabled
    this.stakableProtocol = stakableProtocol
  }

  static query() {
    return StakingPoolCollection.all()
  }

  get isActive() {
    return !this.disabled
  }

  get isExpired() {
    const market = PendleMarket.query().find(this.inputToken.address)
    return market.status === 'expired'
  }

  get id() {
    return `${this.address}-${this.inputToken.address}-${this.inputToken.expiry}`
  }

  get chainId() {
    return sdkChainId
  }

  get sdkSelf() {
    return SdkStakingPool.find(this.address, this.inputToken.address, sdkChainId)
  }

  contract({ signer, provider }) {
    const { stake, unstake, balanceOf, rewardAprs, getTotalStaked } = this.sdkSelf.methods({
      signer,
      provider,
      chainId: sdkChainId,
    })

    const inputToken = this.inputToken

    return {
      stake(tokenAmount) {
        return contractPromiseHandler(() => stake(tokenAmount))
      },
      unstake(tokenAmount) {
        return contractPromiseHandler(() => unstake(tokenAmount))
      },
      balanceOf(walletAddress) {
        return new Promise((resolve, reject) => {
          if (walletAddress) {
            balanceOf(walletAddress)
              .then((response) => {
                resolve(response.amount)
              })
              .catch((error) => {
                reject(error)
              })
          } else {
            resolve(new TokenAmount(inputToken, 0, false))
          }
        })
      },
      rewardAprs: (token) => {
        const isNotPendle = Boolean(token)
        if (sdkChainId === 1 && isNotPendle) {
          const merkleDistributor = new MerkleDistributor({ chainId: 1 })
          return merkleDistributor
            .contract({ signer, provider })
            .rewardAPR(token)
        }
        return rewardAprs()
      },
      getTotalStaked,
    }
  }
}
